import { Pipe, PipeTransform } from '@angular/core';
import { OmitEntityProp } from '@isaia/entity';

export function getGeographyStoreName<T extends { name?: string }>(store?: OmitEntityProp<T>) {
  return `${store?.name || ''}`.trim();
}

@Pipe({
  standalone: true,
  name: 'isaGeographyStoreName',
})
export class GeographyStoreNamePipe implements PipeTransform {
  public transform(store?: Parameters<typeof getGeographyStoreName>[0]) {
    return getGeographyStoreName(store);
  }
}
