import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpGetOptions, HttpPaginationParams, HttpPostOptions, HttpResponse, HttpResponseWithPagination } from '@isaia/entity/http';
import { map } from 'rxjs';
import { ORDER_API_ORIGIN } from './order-api-origin.token';
import { createId } from '@isaia/id';
import { SessionProduct } from '@isaia/entity/session';
import {
  createOrderItem,
  createOrderItemMedia,
  OrderItem,
  OrderItemPayloadXhr,
  OrderItemMediaXhr,
  OrderItemStatus,
  OrderItemXhr,
  OrderHistoryXhr,
  createOrderHistory,
  OrderHistory,
} from '@isaia/entity/order';
import { clone } from '@isaia/clone';
import { createMoney } from '@qwentes/money';
import { GeographyStore } from '@isaia/entity/geography';
import { Customer } from '@isaia/entity/customer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NO_MEASUREMENT_VALUE } from '@isaia/measurement';

export enum GetOrderItemsParams {
  FilterStore = 'filter[storeId]',
  FilterStatus = 'filter[status]',
  FilterSalesAssociate = 'filter[salesAssociate]',
  FilterCustomer = 'filter[customerId]',
  FilterCategory = 'filter[categoryId]',
  FilterMeasurement = 'filter[sartorialMeasurements]',
  FilterExternalReferenceId = 'filter[externalReferenceId]',
  FilterAggregatorId = 'filter[aggregatorId]',
}

export enum GetOrderHistoryParams {
  FilterStore = 'filter[storeId]',
  FilterQuery = 'filter[q]',
}

type HttpGetOrdersResponse = HttpResponseWithPagination<OrderItemXhr[]>;
export type HttpGetOrdersOptions = HttpGetOptions<Partial<Record<GetOrderItemsParams, string | number | null>>>;

type HttpGetOrderResponse = HttpResponseWithPagination<OrderItemXhr[]>;
export type HttpGetOrderOptions = HttpGetOptions;

export type HttpCreateOrderItemData = {
  item: SessionProduct;
  model?: string;
  sessionId: string;
  salesAssociateId: string;
  customerId: string;
  measurementIds: string[];
  store: string;
  status: OrderItemStatus;
  externalReferenceId?: string;
};
export type HttpCreateOrderItemResponse = HttpGetOrdersResponse;

export type HttpUpdateOrderItemData = {
  orderItem: OrderItem;
  updateWith: Partial<Pick<HttpCreateOrderItemData, 'item' | 'status' | 'measurementIds' | 'model'>>;
};
type HttpUpdateOrderItemResponse = HttpResponse<OrderItemXhr[]>;

export type HttpUpdateOrderItemsStatusData = {
  orderItemIds: string[];
  status: OrderItemStatus;
  externalReferenceId: string;
  storeId: string;
};

export type HttpGetOrderHistoryOptions<T = void> = T extends object
  ? HttpGetOptions<HttpPaginationParams & T>
  : HttpGetOptions<HttpPaginationParams>;

export type HttpGetOrderHistoryDwhOptions = HttpGetOrderHistoryOptions<Partial<Record<GetOrderHistoryParams, string | null>>>;
export type HttpGetOrderHistoryMtmOptions = HttpGetOrderHistoryOptions<Partial<Record<GetOrderHistoryParams, string | null>>>;

export type HttpGetCustomerOrderHistoryOptions = HttpGetOptions<HttpPaginationParams>;

interface HttpSendEmailXhrPayload {
  to: string[];
  subject: string;
  body: string;
  cc?: string[];
}

type HttpSendToProductionResponse = HttpResponse<void>;

export interface HttpSendToProductionData extends HttpSendEmailXhrPayload {
  legal: string[];
  storeName: string;
  orderId: OrderHistory['id'];
  storeId: GeographyStore['id'];
}

export type HttpSendOnlyProductionEmailData = Omit<HttpSendToProductionData, 'storeName' | 'legal'>;

@Injectable()
export class OrderApiService {
  private readonly http = inject(HttpClient);
  private readonly ORDER_API_ORIGIN = inject(ORDER_API_ORIGIN).order;
  private readonly ORDER_HISTORY_API_ORIGIN = inject(ORDER_API_ORIGIN).orderHistory;
  private readonly ORDER_REFERENCE_API_ORIGIN = inject(ORDER_API_ORIGIN).orderReferenceId;

  private createTotalPriceRawString(unitPrice: string, quantity: number) {
    return createMoney(unitPrice).multiply(quantity).formatToRawString();
  }

  public getOrderItems(options?: HttpGetOrdersOptions) {
    return this.http.get<HttpGetOrdersResponse>(this.ORDER_API_ORIGIN, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return {
          pagination: { ...response.pagination },
          orderItems: response.data.map(createOrderItem),
        };
      }),
    );
  }

  public getOrderItem(orderItemId: string, options?: HttpGetOrderOptions) {
    return this.http.get<HttpGetOrderResponse>(`${this.ORDER_API_ORIGIN}/${orderItemId}`, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createOrderItem)[0];
      }),
    );
  }

  private patchMeasurementIdsToPreventPDFServerError(sartorialMeasurements: string[]) {
    const length = sartorialMeasurements?.length || 0;
    if (length >= 2) {
      return sartorialMeasurements;
    }
    if (length == 1) {
      return [...sartorialMeasurements, NO_MEASUREMENT_VALUE];
    }
    return [NO_MEASUREMENT_VALUE, NO_MEASUREMENT_VALUE];
  }

  public createOrderItem(data: HttpCreateOrderItemData, options?: HttpPostOptions) {
    const { unitPrice, categoryId, quantity, ...partialSessionProduct } = data.item;
    const item: OrderItemPayloadXhr = {
      id: createId(),
      aggregatorId: data.sessionId,
      salesAssociate: data.salesAssociateId,
      storeId: data.store,
      categoryId: +categoryId,
      unitPrice,
      totalPrice: this.createTotalPriceRawString(unitPrice, quantity),
      itemData: clone(partialSessionProduct),
      status: data.status,
      customerId: data.customerId,
      quantity: quantity,
      sartorialMeasurements: this.patchMeasurementIdsToPreventPDFServerError(data.measurementIds),
      itemModel: data.model,
      externalReferenceId: data.externalReferenceId,
    };
    return this.http.post<HttpCreateOrderItemResponse>(this.ORDER_API_ORIGIN, item, options as unknown as HttpPostOptions);
  }

  public updateOrderItem(data: HttpUpdateOrderItemData, options?: HttpPostOptions) {
    const { orderItem, updateWith } = data;
    const { unitPrice, categoryId, quantity, ...originalSessionProduct } = orderItem.itemData;
    const {
      unitPrice: updatedUnitPrice,
      categoryId: updatedCategoryId,
      quantity: updatedQuantity,
      ...updatedSessionProduct
    } = updateWith.item || ({} as SessionProduct);
    const finalUnitPrice = updatedUnitPrice || unitPrice;
    const item: OrderItemPayloadXhr = {
      id: orderItem.id,
      aggregatorId: orderItem.aggregatorId,
      salesAssociate: orderItem.salesAssociateId,
      storeId: orderItem.storeId,
      categoryId: +(updatedCategoryId || categoryId),
      unitPrice: finalUnitPrice,
      totalPrice: this.createTotalPriceRawString(finalUnitPrice, quantity),
      externalReferenceId: updateWith.item?.externalReferenceId,
      itemData: clone(updatedSessionProduct || originalSessionProduct),
      status: updateWith.status || orderItem.status,
      customerId: orderItem.customerId,
      quantity: updatedQuantity || quantity,
      sartorialMeasurements: this.patchMeasurementIdsToPreventPDFServerError(updateWith.measurementIds || orderItem.measurementIds),
      itemModel: updateWith.model || orderItem.model,
    };
    return this.http
      .put<HttpUpdateOrderItemResponse>(`${this.ORDER_API_ORIGIN}/${item.id}`, item, options as unknown as HttpPostOptions)
      .pipe(
        map((response) => {
          return response.data.map(createOrderItem)[0];
        }),
      );
  }

  public updateOrderItemsStatus(aggregatorId: string, data: HttpUpdateOrderItemsStatusData) {
    return this.http.put<HttpUpdateOrderItemResponse>(`${this.ORDER_API_ORIGIN}/${aggregatorId}/update/status`, data);
  }

  public sendToProduction(data: HttpSendToProductionData, options?: HttpPostOptions) {
    return this.http.post<HttpSendToProductionResponse>(
      `${this.ORDER_API_ORIGIN}/send-to-production/${data.orderId}`,
      {
        legal: data.legal,
        storeName: data.storeName,
        storeId: data.storeId,
        body: data.body,
        cc: data.cc,
        to: data.to,
        subject: data.subject,
      },
      options as unknown as HttpPostOptions,
    );
  }

  public sendOnlyProductionEmail(data: HttpSendOnlyProductionEmailData, options?: HttpPostOptions) {
    return this.http.post<HttpSendToProductionResponse>(
      `${this.ORDER_API_ORIGIN}/send-email/${data.orderId}`,
      {
        storeId: data.storeId,
        body: data.body,
        cc: data.cc,
        to: data.to,
        subject: data.subject,
      },
      options as unknown as HttpPostOptions,
    );
  }

  public uploadOrderMedia(media: File | File[], options?: HttpPostOptions) {
    const formData = new FormData();
    const ensureArray = Array.isArray(media) ? media : [media];
    ensureArray.forEach((value) => formData.append('file[]', value));
    return this.http
      .post<HttpResponse<OrderItemMediaXhr[]>>(`${this.ORDER_API_ORIGIN}/images/upload`, formData, options as unknown as HttpPostOptions)
      .pipe(map((res) => res.data.map(createOrderItemMedia)));
  }

  private getOrderFile(options?: HttpGetOptions) {
    return this.http
      .get<HttpResponse<OrderItemMediaXhr[]>>(`${this.ORDER_API_ORIGIN}/images/list-files`, options as unknown as HttpPostOptions)
      .pipe(map((res) => res.data.map(createOrderItemMedia)));
  }

  public getOrderMedia(name: string | string[], options?: HttpGetOptions) {
    const names = Array.isArray(name) ? name.filter((v) => !!v).join(',') : name;
    return this.getOrderFile({ ...options, params: { ...options?.params, ['file_name']: names } });
  }

  public getOrderPdf(id: string, options?: HttpGetOptions) {
    return this.getOrderFile({ ...options, params: { ...options?.params, ['file_name']: `${id}.pdf` } }).pipe(map((items) => items?.[0]));
  }

  public getOrderHistory(orderId: OrderHistory['id'], options?: HttpGetOrderHistoryOptions) {
    const url = `${this.ORDER_HISTORY_API_ORIGIN}/${orderId}`;
    return this.http.get<HttpResponse<OrderHistoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return createOrderHistory(response.data?.[0]);
      }),
    );
  }

  public getStoreOrderHistory(storeId: GeographyStore['id'], options?: HttpGetOrderHistoryOptions) {
    const url = `${this.ORDER_HISTORY_API_ORIGIN}/byStore/${storeId}`;
    return this.http.get<HttpResponse<OrderHistoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createOrderHistory);
      }),
    );
  }

  public getCustomerOrderHistory(customerId: Customer['id'], options?: HttpGetCustomerOrderHistoryOptions) {
    const url = `${this.ORDER_HISTORY_API_ORIGIN}/byCustomer/${customerId}`;
    return this.http.get<HttpResponse<OrderHistoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createOrderHistory);
      }),
    );
  }

  public getOrderHistoryDwh(options?: HttpGetOrderHistoryDwhOptions) {
    const url = `${this.ORDER_HISTORY_API_ORIGIN}/dwh`;
    return this.http.get<HttpResponse<OrderHistoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createOrderHistory);
      }),
    );
  }

  public getOrderHistoryMtm(options?: HttpGetOrderHistoryMtmOptions) {
    const url = `${this.ORDER_HISTORY_API_ORIGIN}/mtm`;
    return this.http.get<HttpResponse<OrderHistoryXhr[]>>(url, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return response.data.map(createOrderHistory);
      }),
    );
  }

  #getOrderReferenceId(options: HttpGetOptions<{ context: 'WHL' }>) {
    return this.http
      .get<HttpResponse<{ referenceId: string }>>(this.ORDER_REFERENCE_API_ORIGIN, options as unknown as HttpGetOptions)
      .pipe(map((res) => res.data.referenceId));
  }

  public getOrderReferenceIdWHL() {
    return this.#getOrderReferenceId({ params: { context: 'WHL' } });
  }
}
