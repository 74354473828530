// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { GeographyCompanyXhr } from './geography-company.xhr';

const ENTITY = 'GEOGRAPHY_COMPANY';

export interface GeographyCompany extends IsaiaEntity<typeof ENTITY> {
  id: string;
  countryCode: string;
  name: string;
  priceList?: string;
}

const COMPANY_PRICE_LIST_BY_COUNTRY_CODE: Record<string, any> = {
  ['Diretto']: {
    AU: 'SEEU', // converted from AUS
    AT: 'SEEU', // converted from AUT
    CA: 'SUFI', // converted from CAN
    CN: 'SEEU', // converted from CHN
    CY: 'SEEU', // converted from CYP
    DO: 'SEEU', // converted from DOM
    FI: 'SOEU', // converted from FIN
    GH: 'SEEU', // converted from GHA
    GR: 'SOEU', // converted from GRC
    IN: 'SEPE', // converted from IND
    IR: 'SEEU', // converted from IRN
    IT: 'MI04', // converted from ITA
    JP: 'SAJP', // converted from JPN
    KW: 'SEEU', // converted from KWT
    LU: 'SEEU', // converted from LUX
    MX: 'SEEU', // converted from MEX
    MN: 'SEEU', // converted from MNG
    NL: 'SEEU', // converted from NLD
    NO: 'SEEU', // converted from NOR
    QA: 'SEEU', // converted from QAT
    SA: 'SEEU', // converted from SAU
    RS: 'SEEU', // converted from SRB
    ES: 'SEEU', // converted from ESP
    TW: 'SEEU', // converted from TWN
    TR: 'SEEU', // converted from TUR
    GB: 'SEEU', // converted from GBR
    AE: 'SEEU', // converted from ARE
    US: 'SULI', // converted from USA
    HK: 'SEEU', // converted from HGK
  },
  ['Retail']: {
    CA: 'INC9', // converted from CAN
    CN: 'SEEU', // converted from CHN
    IT: 'IN04', // converted from ITA
    JP: 'IJPM', // converted from JPN
    CH: 'INCM', // converted from CHE
    GB: 'ING4', // converted from GBR
    US: 'IN04', // converted from USA
    HK: 'INH4', // converted from HGK
  },
  invalidCodes: {
    direct: ['ECD'],
    retail: [],
  },
};

export function createGeographyCompany(options: Partial<GeographyCompanyXhr>): GeographyCompany {
  const priceList = COMPANY_PRICE_LIST_BY_COUNTRY_CODE[options?.salesChannel as string]?.[options.isoCountryCode as string];
  return markIsaiaEntity(ENTITY, {
    id: `${options.id}`,
    countryCode: options.isoCountryCode,
    name: options.name,
    priceList: priceList || 'COMPANY-PRICE-LIST-NOT-FOUND',
  });
}

export function isInstanceOfGeographyCompany(value: unknown): value is GeographyCompany {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
