import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CUSTOMER_API_ORIGIN } from './customer-api-origin.token';
import { map } from 'rxjs';
import { HttpGetOptions, HttpPaginationParams, HttpPostOptions, HttpResponseWithPagination } from '@isaia/entity/http';
import { createCustomer, Customer, CustomerXhr } from '@isaia/entity/customer';
import { createId } from '@isaia/id';
import { SetRequired } from 'type-fest';

export enum GetCustomersParams {
  FilterId = 'filter[id]',
  FilterQ = 'filter[q]',
  FilterStoreId = 'filter[primaryStore]',
}

export enum GetCustomerParams {
  FilterStoreId = 'filter[primaryStore]',
}

type HttpGetCustomersResponse = HttpResponseWithPagination<CustomerXhr[]>;
export type HttpGetCustomersOptions = HttpGetOptions<Partial<Record<GetCustomersParams, string | number | null>> & HttpPaginationParams>;
export type HttpGetCustomerOptions = HttpGetOptions<Partial<Record<GetCustomerParams, string | number | null>>>;

export type HttpCreateCustomerData = SetRequired<
  Partial<Omit<Customer, '_entity'>>,
  'id' | 'email' | 'firstName' | 'lastName' | 'primaryStore' | 'primarySalesAssociate'
>;
type HttpCreateCustomerResponse = HttpResponseWithPagination<[CustomerXhr]>;

export type HttpUpdateCustomerData = HttpCreateCustomerData;
type HttpUpdateCustomerResponse = HttpResponseWithPagination<[CustomerXhr]>;

function createCustomerApi(customer: HttpCreateCustomerData): Omit<CustomerXhr, 'createdAt' | 'source'> {
  return {
    id: customer.id,
    externalId: customer.externalId,
    firstName: customer.firstName,
    lastName: customer.lastName,
    email: customer.email,
    phone: customer.phone,
    lastPurchaseDate: customer.lastPurchaseDate || null,
    primaryStore: customer.primaryStore,
    primarySalesAssociate: customer.primarySalesAssociate,
  };
}

@Injectable()
export class CustomerApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(CUSTOMER_API_ORIGIN);

  public getCustomers(options?: HttpGetCustomersOptions) {
    return this.http.get<HttpGetCustomersResponse>(this.API_ORIGIN, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return {
          pagination: { ...response.pagination },
          customers: response.data.map(createCustomer),
        };
      }),
    );
  }

  public getCustomer(customerId: Customer['id'], options?: HttpGetCustomerOptions) {
    return this.http
      .get<HttpGetCustomersResponse>(`${this.API_ORIGIN}/${customerId}`, options as unknown as HttpGetOptions)
      .pipe(map((res) => createCustomer(res.data[0])));
  }

  public createCustomer(customer: Omit<HttpCreateCustomerData, 'id'>, options?: HttpPostOptions) {
    const body = createCustomerApi({ ...customer, id: createId() });
    return this.http
      .post<HttpCreateCustomerResponse>(this.API_ORIGIN, body, options as unknown as HttpPostOptions)
      .pipe(map((res) => createCustomer(res.data[0])));
  }

  public updateCustomer(customer: HttpUpdateCustomerData, options?: HttpPostOptions) {
    const body = createCustomerApi(customer);
    return this.http
      .put<HttpUpdateCustomerResponse>(`${this.API_ORIGIN}/${customer.id}`, body, options as unknown as HttpPostOptions)
      .pipe(map((res) => createCustomer(res.data[0])));
  }
}
