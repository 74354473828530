import { inject, Injectable, Injector } from '@angular/core';
import {
  GetOrderItemsParams,
  HttpGetCustomerOrderHistoryOptions,
  HttpGetOrderHistoryDwhOptions,
  HttpGetOrderHistoryMtmOptions,
  HttpGetOrderHistoryOptions,
  HttpGetOrderOptions,
  HttpGetOrdersOptions,
  HttpSendOnlyProductionEmailData,
  HttpSendToProductionData,
  HttpUpdateOrderItemData,
  OrderApiService,
} from '@isaia/order';
import { HttpGetOptions, HttpPostOptions } from '@isaia/entity/http';
import { Session } from '@isaia/entity/session';
import { catchError, forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';
import { OrderHistory, OrderItem, OrderItemStatus } from '@isaia/entity/order';
import { keyBy } from 'lodash-es';
import { CustomerService } from '../customer';
import { Customer } from '@isaia/entity/customer';
import { GeographyRepository } from '../geography';
import { injectOrderDialog } from './order.dialog';
import { GeographyStore } from '@isaia/entity/geography';
import { ApparelRepository } from '../apparel';
import { CheckoutRetailStrategy } from './checkout-retail.strategy';
import { CheckoutWholesaleStrategy } from './checkout-wholesale.strategy';
import { EnrichedBasketItem } from '../basket';
import { AuthRepository } from '../auth';

export interface OrderItemsGrouped {
  id: string;
  customer: Customer;
  items: OrderItem[];
}

export interface OrderHistoryHydrated extends OrderHistory {
  customer?: Customer;
}

export type ExternalReferenceIdKeyed = Record<string, string[]>;

@Injectable()
export class OrderService {
  private readonly orderApiService = inject(OrderApiService);
  private readonly orderDialog = injectOrderDialog();
  private readonly customerService = inject(CustomerService);
  private readonly geographyRepository = inject(GeographyRepository);
  private readonly injector = inject(Injector);
  private readonly apparelRepository = inject(ApparelRepository);
  private readonly authRepository = inject(AuthRepository);
  private readonly checkoutStrategy = this.authRepository.isContextWholesale()
    ? this.injector.get(CheckoutWholesaleStrategy)
    : this.injector.get(CheckoutRetailStrategy);

  public getOrderItemsGroupedHydrated(options?: HttpGetOrdersOptions) {
    return this.getOrderItems(options).pipe(
      switchMap(({ orderItems }) => {
        const customerIds = orderItems.map((i) => i.customerId);
        return forkJoin({ customers: this.customerService.getCustomersByIds(customerIds), orderItems: of(orderItems) });
      }),
      map(({ orderItems, customers }) => {
        const customerKeyed = keyBy(customers, (c) => c.id);
        const ordersKeyed = orderItems.reduce<Record<string, OrderItemsGrouped>>((acc, orderItem) => {
          const id = orderItem.aggregatorId;
          if (!acc[id]) {
            acc[id] = { id, customer: customerKeyed[orderItem.customerId], items: [] as OrderItem[] };
          }
          acc[id].items.push(orderItem);
          return acc;
        }, {});
        return Object.values(ordersKeyed);
      }),
      catchError(() => []),
    );
  }

  private shouldAddStoreIdToRequest() {
    return {
      [GetOrderItemsParams.FilterStore]: this.authRepository.isContextWholesale() ? this.geographyRepository.$currentStore().id : null,
    };
  }

  public getOrderItems(options?: HttpGetOrdersOptions) {
    return this.orderApiService.getOrderItems({
      ...options,
      params: { [GetOrderItemsParams.FilterStore]: this.geographyRepository.$currentStore()?.id, ...options?.params },
    });
  }

  public getOrderItem(orderItemId: string, options?: HttpGetOrderOptions) {
    return this.orderApiService.getOrderItem(orderItemId, options);
  }

  public getOrderItemsByAggregatorId(aggregatorId: string, options?: HttpGetOrdersOptions) {
    if (!aggregatorId) {
      return of([]);
    }
    return this.getOrderItems({
      ...options,
      params: { ...options?.params, [GetOrderItemsParams.FilterAggregatorId]: aggregatorId },
    }).pipe(map((res) => res.orderItems));
  }

  public sendAllItemsByAggregatorIdToReadyForProduction(item: OrderItem) {
    if (!item.aggregatorId) {
      return throwError(() => 'AggregatorId is required');
    }
    if (!item.externalReferenceId) {
      return throwError(() => 'ExternalReferenceId is required');
    }
    return this.getOrderItemsByAggregatorId(item.aggregatorId).pipe(
      map((items) => {
        return items.filter((i) => {
          return (
            this.apparelRepository.hasAllMandatoryTaxonomyDefined(i.itemData.categoryId, i.itemData.taxonomies, i.itemData.suitVest) &&
            i.status !== OrderItemStatus.ReadyForProduction
          );
        });
      }),
      switchMap((items) => {
        if (!items.length) {
          return of(null);
        }
        const ids = items.map((i) => i.id);
        return this.orderApiService.updateOrderItemsStatus(item.aggregatorId, {
          orderItemIds: ids,
          storeId: item.storeId,
          status: OrderItemStatus.ReadyForProduction,
          externalReferenceId: item.externalReferenceId!,
        });
      }),
    );
  }

  public getExistingExternalReferenceId(aggregatorIds?: string[], options?: HttpGetOrdersOptions) {
    if (!aggregatorIds?.length) {
      return of({} as ExternalReferenceIdKeyed);
    }
    return this.getOrderItems({
      ...options,
      params: { ...options?.params, [GetOrderItemsParams.FilterAggregatorId]: aggregatorIds.join(',') },
    }).pipe(
      switchMap((res) => {
        const externalIdsMapByAggregatorId = res.orderItems.reduce<ExternalReferenceIdKeyed>((acc, orderItem) => {
          const aggregatorId = orderItem.aggregatorId;
          const externalReferenceId = orderItem.externalReferenceId;
          if (!acc[aggregatorId]) {
            acc[aggregatorId] = [];
          }
          if (externalReferenceId && !acc[aggregatorId].includes(externalReferenceId)) {
            acc[aggregatorId].push(externalReferenceId);
          }
          return acc;
        }, {});
        return of(externalIdsMapByAggregatorId);
      }),
    );
  }

  public checkout(session: Session, enrichedBasketItems: EnrichedBasketItem[], options?: HttpPostOptions) {
    return this.checkoutStrategy.checkout(session, enrichedBasketItems, options);
  }

  public updateOrderItem(data: HttpUpdateOrderItemData, options?: HttpPostOptions) {
    const orderStoreId = data.orderItem.storeId;
    const currentStore = this.geographyRepository.$currentStore();
    if (orderStoreId !== currentStore.id) {
      const orderStore = this.geographyRepository.getStore(orderStoreId);
      this.orderDialog.openUpdateOrderStoreMismatch({
        orderStore: orderStore.name,
        currentStore: currentStore.name,
      });
      return throwError(() => `UPDATE_ORDER_STORE_MISMATCH`);
    }
    return this.orderApiService.updateOrderItem(data, options);
  }

  public sendToProduction(data: HttpSendToProductionData, options?: HttpPostOptions) {
    return this.orderApiService.sendToProduction(data, options);
  }

  public sendOnlyProductionEmail(data: HttpSendOnlyProductionEmailData, options?: HttpPostOptions) {
    return this.orderApiService.sendOnlyProductionEmail(data, options);
  }

  public uploadOrderMedia(media: File | File[], options?: HttpPostOptions) {
    return this.orderApiService.uploadOrderMedia(media, options);
  }

  public getOrderMedia(name: string | string[], options?: HttpGetOptions) {
    return this.orderApiService.getOrderMedia(name, options);
  }

  public getOrderHistory(orderId: OrderHistory['id'], options?: HttpGetOrderHistoryOptions) {
    return this.orderApiService.getOrderHistory(orderId, {
      ...options,
      params: { ...this.shouldAddStoreIdToRequest(), ...options?.params },
    });
  }

  public getStoreOrderHistory(storeId: GeographyStore['id'], options?: HttpGetOrderHistoryOptions) {
    return this.orderApiService.getStoreOrderHistory(storeId, options);
  }

  private hydrateWithCustomer<T extends { customerId: string }>(entities?: T[]): Observable<Array<T & { customer?: Customer }>> {
    if (!entities) {
      return of([]);
    }
    const customerIds = entities.map((o) => o.customerId);
    if (!customerIds?.length) {
      return of(entities);
    }
    return this.customerService.getCustomersByIds(customerIds).pipe(
      map((customers) => {
        const customersKeyed = keyBy(customers, 'id');
        return entities.map((o) => {
          return { ...o, customer: customersKeyed[o.customerId] || undefined };
        });
      }),
    );
  }

  public getStoreOrderHistoryHydrated(storeId: GeographyStore['id'], options?: HttpGetOrderHistoryOptions) {
    return this.getStoreOrderHistory(storeId, options).pipe(switchMap((orders) => this.hydrateWithCustomer(orders)));
  }

  public getCustomerOrderHistory(customerId: Customer['id'], options?: HttpGetCustomerOrderHistoryOptions) {
    return this.orderApiService.getCustomerOrderHistory(customerId, {
      ...options,
      params: { ...this.shouldAddStoreIdToRequest(), ...options?.params },
    });
  }

  public getOrderPdf(externalReferenceId: string, options?: HttpGetOptions) {
    return this.orderApiService.getOrderPdf(externalReferenceId, options);
  }

  public getOrderHistoryDwh(options?: HttpGetOrderHistoryDwhOptions) {
    return this.orderApiService.getOrderHistoryDwh(options).pipe(switchMap((orders) => this.hydrateWithCustomer(orders)));
  }

  public getOrderHistoryMtm(options?: HttpGetOrderHistoryMtmOptions) {
    return this.orderApiService.getOrderHistoryMtm(options).pipe(switchMap((orders) => this.hydrateWithCustomer(orders)));
  }
}
