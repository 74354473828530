import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { LocalStorageService } from '@isaia/local-storage';
import { defineSentryUser } from '../sentry';
import { getUserFullName } from './auth-full-name.pipe';
import { isEqual } from 'lodash-es';
import { createAuthUserFromCognito } from './auth-cognito.model';

export interface AuthState {
  token?: string;
  config?: {
    storeId?: string | null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  private readonly localStorageService = inject(LocalStorageService);
  private readonly $store = signal<AuthState | undefined>(undefined);

  private readonly $config = computed(() => this.$store()?.config);
  public readonly $token = computed(() => this.$store()?.token);
  public readonly $user = computed(() => createAuthUserFromCognito(this.$token()), { equal: isEqual });
  public readonly $email = computed(() => this.$user()?.email || '');
  public readonly sentToProductionEmails = computed(() => this.$user()?.sentToProductionEmails || []);
  public readonly $isLoggedIn = computed(() => !!this.$token() && !!this.$user()?.email);
  public readonly $storeId = computed(() => this.$config()?.storeId || this.$user()?.defaultStore);
  public readonly fullName = computed(() => getUserFullName(this.$user()));
  public readonly isAdmin = computed(() => !!this.$user()?.permissions?.length);
  public readonly isMtmUser = computed(() => !!this.$user()?.defaultStore);
  public readonly isContextWholesale = computed(() => this.$user()?.defaultStore?.length === 6);

  constructor() {
    this.localStorageService.syncValue(
      'auth',
      () => this.$store(),
      (state) => this.setStore(state),
    );

    effect(() => {
      defineSentryUser(this.$user());
    });
  }

  public setStore(state?: AuthState) {
    this.$store.set(state);
  }

  public setToken(token: AuthState['token']) {
    this.$store.set({ token });
  }

  public updateToken(token: AuthState['token']) {
    this.$store.update((state) => {
      return { ...state, token };
    });
  }

  public updateConfig(config: AuthState['config']) {
    this.$store.update((state) => {
      return { ...state, config: { ...state?.config, ...config } };
    });
  }

  public onLogout(fn: () => void) {
    return effect(() => {
      if (!this.$isLoggedIn()) {
        untracked(fn);
      }
    });
  }
}
