import { ApparelCategorySlug, ApparelCategorySlugMtm } from '@isaia/entity/apparel';
import { SetOptional } from 'type-fest';
import { createMoney, Money, normalizeMoneyAmount } from '@qwentes/money';

type PriceExtraChargeCurrency = 'EUR' | 'USD' | string;

const PRICE_EXTRA_CHARGE_BY_CATEGORY_SLUG: Record<PriceExtraChargeCurrency, Record<ApparelCategorySlugMtm, number>> = {
  EUR: {
    [ApparelCategorySlug.Suit]: 1000,
    [ApparelCategorySlug.Jacket]: 625,
    [ApparelCategorySlug.Overcoat]: 625,
    [ApparelCategorySlug.Shirt]: 125,
    [ApparelCategorySlug.Vest]: 125,
    [ApparelCategorySlug.Trouser]: 375,
  },
  USD: {
    [ApparelCategorySlug.Suit]: 1200,
    [ApparelCategorySlug.Jacket]: 750,
    [ApparelCategorySlug.Overcoat]: 750,
    [ApparelCategorySlug.Shirt]: 150,
    [ApparelCategorySlug.Vest]: 150,
    [ApparelCategorySlug.Trouser]: 450,
  },
  GBP: {
    [ApparelCategorySlug.Suit]: 900,
    [ApparelCategorySlug.Jacket]: 565,
    [ApparelCategorySlug.Overcoat]: 565,
    [ApparelCategorySlug.Shirt]: 115,
    [ApparelCategorySlug.Vest]: 115,
    [ApparelCategorySlug.Trouser]: 340,
  },
  CAD: {
    [ApparelCategorySlug.Suit]: 1500,
    [ApparelCategorySlug.Jacket]: 940,
    [ApparelCategorySlug.Overcoat]: 940,
    [ApparelCategorySlug.Shirt]: 190,
    [ApparelCategorySlug.Vest]: 190,
    [ApparelCategorySlug.Trouser]: 565,
  },
  CHF: {
    [ApparelCategorySlug.Suit]: 1000,
    [ApparelCategorySlug.Jacket]: 625,
    [ApparelCategorySlug.Overcoat]: 625,
    [ApparelCategorySlug.Shirt]: 125,
    [ApparelCategorySlug.Vest]: 125,
    [ApparelCategorySlug.Trouser]: 375,
  },
  JPY: {
    [ApparelCategorySlug.Suit]: 146000,
    [ApparelCategorySlug.Jacket]: 92000,
    [ApparelCategorySlug.Overcoat]: 92000,
    [ApparelCategorySlug.Shirt]: 19000,
    [ApparelCategorySlug.Vest]: 19000,
    [ApparelCategorySlug.Trouser]: 55000,
  },
};

function normalizeExtraCharge(value: number, { currency, decimals }: SetOptional<Pick<Money, 'currency' | 'decimals'>, 'decimals'>) {
  const amount = normalizeMoneyAmount(value, currency, decimals);
  return createMoney({ amount, currency, decimals });
}

export function getExtraCharge(currency: PriceExtraChargeCurrency, byCategorySlug: ApparelCategorySlugMtm | string) {
  const amount = PRICE_EXTRA_CHARGE_BY_CATEGORY_SLUG[currency]?.[byCategorySlug as ApparelCategorySlugMtm] || 0;
  return normalizeExtraCharge(amount, { currency });
}

export type ExtraChargeStrategy = (
  currency: PriceExtraChargeCurrency,
  byCategorySlug: ApparelCategorySlugMtm | string,
) => {
  add(price: Money): Money;

  remove(price: Money): Money;
};
