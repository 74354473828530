// eslint-disable-next-line @nx/enforce-module-boundaries
import pkg from './../../../../package.json';
import { PartialDeep } from 'type-fest';
import { ORIGIN_UAT_API, ORIGIN_LOCAL } from './_origin';

export interface Environment {
  production?: boolean;
  name: string;
  version: string;
  encryptionStorage?: 'aes' | '';
  sentry: {
    dsn: string;
    sampleRate: number;
  };
  config: {
    polling: {
      timingInSeconds: number;
      errorWaitForRetryInSeconds: number;
    };
  };
  api: {
    config: string;
    i18n: string;
    category: string;
    customer: string;
    fabric: string;
    getFabric: string;
    price: string;
    measurement: string;
    order: string;
    orderReferenceId: string;
    purchaseHistory: string;
    session: string;
    geography: {
      store: string;
      company: string;
    };
    auth: {
      redirectLogin: string;
      redirectPostLogin: string;
      decode: string;
      logout: string;
      user: string;
    };
  };
}

export function createEnvironment(options?: PartialDeep<Environment> & { origin?: { app?: string; api?: string } }): Environment {
  const origin = { app: options?.origin?.app || ORIGIN_LOCAL, api: options?.origin?.api || ORIGIN_UAT_API };
  return {
    name: 'development',
    encryptionStorage: '',
    ...options,
    version: pkg.config.mtm.version,
    sentry: {
      dsn: options?.sentry?.dsn || 'https://ebb8c068b5e424a187085703c176cb12@o4505794524086272.ingest.sentry.io/4505991438860288',
      sampleRate: options?.sentry?.sampleRate || 0.1,
    },
    config: {
      polling: {
        timingInSeconds: options?.config?.polling?.timingInSeconds || 10,
        errorWaitForRetryInSeconds: options?.config?.polling?.errorWaitForRetryInSeconds || 1,
      },
    },
    api: {
      config: options?.api?.config || `${origin.api}/config`,
      i18n: options?.api?.i18n || `${origin.app}/assets/i18n`,
      category: options?.api?.category || `${origin.api}/data`,
      customer: options?.api?.customer || `${origin.api}/data/customers`,
      fabric: options?.api?.fabric || `${origin.api}/data/fabrics`,
      getFabric: options?.api?.fabric || `${origin.api}/data/get-fabric`,
      price: options?.api?.price || `${origin.api}/data`,
      measurement: options?.api?.measurement || `${origin.api}/data/sartorial-measurements`,
      order: options?.api?.order || `${origin.api}/data/order-items`,
      orderReferenceId: options?.api?.orderReferenceId || `${origin.api}/data/order-reference-id`,
      purchaseHistory: options?.api?.purchaseHistory || `${origin.api}/data/purchase-history`,
      session: options?.api?.session || `${origin.api}/data/sessions`,
      geography: {
        store: options?.api?.geography?.store || `${origin.api}/data/stores`,
        company: options?.api?.geography?.company || `${origin.api}/data/companies`,
      },
      auth: {
        redirectLogin: options?.api?.auth?.redirectLogin || `${origin.api}/auth`,
        redirectPostLogin: options?.api?.auth?.redirectPostLogin || `${origin.app}/auth/login`,
        decode: options?.api?.auth?.decode || `${origin.api}/auth/back`,
        logout: options?.api?.auth?.logout || `${origin.api}/auth/logout`,
        user: options?.api?.auth?.user || `${origin.api}/auth/user`,
      },
    },
  };
}
