// eslint-disable-next-line @nx/enforce-module-boundaries
import { markIsaiaEntity, IsaiaEntity, isIsaiaEntity } from '@isaia/entity';
import { GeographyStoreXhr } from './geography-store.xhr';

const ENTITY = 'GEOGRAPHY_STORE';

export interface GeographyStore extends IsaiaEntity<typeof ENTITY> {
  id: string;
  countryCode: string;
  name: string;
  priceList?: string;
}

export function createGeographyStore(options: Partial<GeographyStoreXhr>): GeographyStore {
  return markIsaiaEntity(ENTITY, {
    id: `${options.id}`,
    countryCode: options.countryCode,
    name: options.name,
    priceList: options.priceList,
  });
}

export function isInstanceOfGeographyStore(value: unknown): value is GeographyStore {
  return typeof value !== 'object' ? false : isIsaiaEntity(value, ENTITY);
}
