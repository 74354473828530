import { inject, Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { GeographyRepository } from './geography.repository';
import { GeographyApiService, HttpGetStoresOptions } from '@isaia/geography';
import { AuthRepository } from '../auth';
import { GeographyCompany, GeographyStore } from '@isaia/entity/geography';

@Injectable()
export class GeographyService {
  private readonly geographyApiService = inject(GeographyApiService);
  private readonly geographyRepository = inject(GeographyRepository);
  private readonly authRepository = inject(AuthRepository);

  public getStores(options?: HttpGetStoresOptions) {
    return this.geographyApiService.getStores(options);
  }

  public getCompanies(options?: HttpGetStoresOptions) {
    return this.geographyApiService.getCompanies(options);
  }

  public getWorkingAreas(options?: HttpGetStoresOptions) {
    const request$: Observable<{ data: GeographyStore[] | GeographyCompany[] }> = this.authRepository.isContextWholesale()
      ? this.getCompanies(options)
      : this.getStores(options);
    return request$.pipe(
      tap((value) => {
        this.geographyRepository.setStores(value.data);
        return value;
      }),
      catchError((e) => {
        this.geographyRepository.setStores([]);
        return throwError(() => e);
      }),
    );
  }
}
