import { Session } from '@isaia/entity/session';
import { HttpPostOptions } from '@isaia/entity/http';
import { OrderItemStatus } from '@isaia/entity/order';
import { getMeasurementIdFromSelectedTaxonomies } from '@isaia/measurement';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { GeographyRepository } from '../geography';
import { AuthRepository } from '../auth';
import { HttpCreateOrderItemResponse, OrderApiService } from '@isaia/order';
import { ApparelRepository } from '../apparel';
import { SessionRepository, SessionService } from '../session';
import { injectOrderDialog } from './order.dialog';
import { CustomerSource } from '@isaia/entity/customer';
import { withErrorPopup } from '../http';
import { CustomerService } from '../customer';
import { CheckoutStrategy } from './checkout.interface';
import { EnrichedBasketItem } from '../basket';

@Injectable()
export class CheckoutRetailStrategy implements CheckoutStrategy {
  private readonly geographyRepository = inject(GeographyRepository);
  private readonly authRepository = inject(AuthRepository);
  private readonly orderApiService = inject(OrderApiService);
  private readonly apparelRepository = inject(ApparelRepository);
  private readonly sessionService = inject(SessionService);
  private readonly orderDialog = injectOrderDialog();
  private readonly sessionRepository = inject(SessionRepository);
  private readonly customerService = inject(CustomerService);

  public checkout(session: Session, enrichedBasketItems: EnrichedBasketItem[], options?: HttpPostOptions) {
    const store = this.geographyRepository.$currentStore();
    const salesAssociateId = this.authRepository.$email();
    const sessionId = session.id;
    const customerId = session.customerId!;
    const orderItems$ = session.basket?.items?.map((item) => {
      return this.orderApiService.createOrderItem(
        {
          item,
          model: this.apparelRepository.getComputedModelId(item.categoryId, item.taxonomies),
          status: OrderItemStatus.Pending,
          customerId,
          sessionId,
          store: store.id,
          salesAssociateId,
          measurementIds: getMeasurementIdFromSelectedTaxonomies(item.taxonomies),
        },
        options,
      );
    });
    return forkJoin(orderItems$ || []).pipe(
      switchMap((ordersResponse) => {
        this.sessionService.deleteSession(session.id, { showConfirmationDialog: false });
        this.orderDialog.openCheckoutSuccess();
        return this.shouldUpdateCustomerPurchaseDate(ordersResponse);
      }),
    );
  }

  // TODO: estrarre shouldUpdateCustomerPurchaseDate in un servizio a parte

  private shouldUpdateCustomerPurchaseDate(ordersResponse: HttpCreateOrderItemResponse[]) {
    const customer = this.sessionRepository.$customer()!;
    if (customer.source === CustomerSource.MadeToMeasure) {
      return this.customerService
        .updateCustomer({ ...customer, lastPurchaseDate: new Date().toISOString() }, { context: withErrorPopup(false) })
        .pipe(
          map(() => ordersResponse),
          catchError((e) => ordersResponse),
        );
    }
    return of(ordersResponse);
  }
}
