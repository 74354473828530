import { inject, Injectable } from '@angular/core';
import { AuthRepository } from './auth.repository';
import { firstValueFrom, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@isaia/entity/http';
import { withAuthentication } from './auth.context';
import { AuthGuard } from './auth.guard';
import { AuthUser } from './auth-user.model';
import { DialogService } from '@isaia/components/dialog';
import { RouterResolverService } from '../router/router.resolver';
import { REQUIRED_COGNITO_USER_PROPS } from './auth-cognito.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly api = environment.api.auth;
  private readonly httpClient = inject(HttpClient);
  private readonly authRepository = inject(AuthRepository);
  private readonly authGuard = inject(AuthGuard);
  private readonly dialogService = inject(DialogService);
  private readonly routerResolverService = inject(RouterResolverService);

  public redirectToLoginProvider() {
    const redirect$ = this.httpClient.get<HttpResponse<string>>(this.api.redirectLogin, {
      params: { redirect_uri: this.api.redirectPostLogin },
    });
    return firstValueFrom(redirect$).then((res) => {
      const url = res.data;
      if (!url) {
        throw new Error('[authService.redirectToLoginProvider]: url not found');
      }
      window.location.href = url;
    });
  }

  public login(options: { code: string }) {
    const login$ = this.decodeCodeProvider(options.code).pipe(
      tap((val) => {
        const token = val.data.token;
        this.authRepository.setToken(token);
        // throwErrorOnInvalidUser MUST be invoked AFTER setToken, otherwise user will be not defined
        this.throwErrorOnInvalidUser(this.authRepository.$user());
      }),
    );
    return firstValueFrom(login$).catch((e) => {
      this.logout();
      throw e;
    });
  }

  private decodeCodeProvider(code: string) {
    return this.httpClient.get<HttpResponse<{ token: string }>>(this.api.decode, {
      context: withAuthentication(false),
      params: { redirect_uri: this.api.redirectPostLogin, code },
    });
  }

  private throwErrorOnInvalidUser(user?: AuthUser) {
    const invalidKeys = REQUIRED_COGNITO_USER_PROPS.filter((prop) => !user?.[prop]);
    if (invalidKeys.length) {
      const message = `Invalid user, missed keys: ${invalidKeys.join(', ')}.\nPlease contact the assistance.`;
      this.dialogService.openPopup({ title: 'Auth Error', message }, { disableClose: true });
      this.routerResolverService.updateUrlWithoutReload({ queryParams: { code: null } });
      throw new Error(message);
    }
  }

  public logout() {
    this.authRepository.setStore(undefined);
    this.authGuard.redirectToNotLoggedRoute();
    const logout$ = this.httpClient.get<HttpResponse<{ status: 'success' }>>(this.api.logout);
    return firstValueFrom(logout$);
  }
}
