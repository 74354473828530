import { inject, Injectable } from '@angular/core';
import {
  HttpGetCustomersOptions,
  CustomerApiService,
  HttpCreateCustomerData,
  HttpUpdateCustomerData,
  GetCustomersParams,
  HttpGetCustomerOptions,
} from '@isaia/customer';
import { Customer } from '@isaia/entity/customer';
import { HttpPostOptions } from '@isaia/entity/http';
import { ApplicationRepository } from '../application';
import { finalize, map, of, tap } from 'rxjs';
import { CustomerRepository } from './customer.repository';
import { AuthRepository } from '../auth';
import { GeographyRepository } from '../geography';
import { Simplify } from 'type-fest';

@Injectable()
export class CustomerService {
  private readonly customerApiService = inject(CustomerApiService);
  private readonly applicationRepository = inject(ApplicationRepository);
  private readonly customerRepository = inject(CustomerRepository);
  private readonly authRepository = inject(AuthRepository);
  private readonly geographyRepository = inject(GeographyRepository);

  private shouldAddStoreIdToRequest() {
    return {
      [GetCustomersParams.FilterStoreId]: this.authRepository.isContextWholesale() ? this.geographyRepository.$currentStore().id : null,
    };
  }

  public getCustomers(options?: HttpGetCustomersOptions) {
    return this.customerApiService.getCustomers({
      ...options,
      params: { ...this.shouldAddStoreIdToRequest(), ...options?.params },
    });
  }

  public getCustomersByIds(ids: string[]) {
    const customerIds = Array.from(new Set(ids));
    return customerIds.length
      ? this.getCustomers({
          params: { ...this.shouldAddStoreIdToRequest(), [GetCustomersParams.FilterId]: customerIds.join(',') || null },
        }).pipe(map((res) => res.customers))
      : of([]);
  }

  public getCustomer(customerId: Customer['id'], options?: HttpGetCustomerOptions) {
    return this.customerApiService
      .getCustomer(customerId, { ...options, params: { ...this.shouldAddStoreIdToRequest(), ...options?.params } })
      .pipe(
        tap((data) => {
          this.customerRepository.addCustomer(data);
        }),
      );
  }

  public createCustomer(
    customer: Simplify<Omit<HttpCreateCustomerData, 'id' | 'primarySalesAssociate' | 'primaryStore'>>,
    options?: HttpPostOptions,
  ) {
    this.applicationRepository.setLoading(true);
    return this.customerApiService.createCustomer({ ...customer, ...this.getPrimaryCustomerInfo() }, options).pipe(
      tap((data) => this.customerRepository.addCustomer(data)),
      finalize(() => {
        this.applicationRepository.setLoading(false);
      }),
    );
  }

  public updateCustomer(
    customer: Simplify<Omit<HttpUpdateCustomerData, 'primarySalesAssociate' | 'primaryStore'>>,
    options?: HttpPostOptions,
  ) {
    this.applicationRepository.setLoading(true);
    return this.customerApiService.updateCustomer({ ...customer, ...this.getPrimaryCustomerInfo() }, options).pipe(
      tap((data) => this.customerRepository.addCustomer(data)),
      finalize(() => {
        this.applicationRepository.setLoading(false);
      }),
    );
  }

  private getPrimaryCustomerInfo(): Simplify<Required<Pick<Customer, 'primaryStore' | 'primarySalesAssociate'>>> {
    return {
      primarySalesAssociate: this.authRepository.$email(),
      primaryStore: this.geographyRepository.$currentStore().id,
    };
  }
}
