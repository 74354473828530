import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { HttpGetOptions, HttpPaginationParams, HttpResponseWithPagination } from '@isaia/entity/http';
import { createGeographyCompany, createGeographyStore, GeographyCompanyXhr, GeographyStoreXhr } from '@isaia/entity/geography';
import { GEOGRAPHY_API_ORIGIN } from './geography-api-origin.token';

export type HttpGetStoresOptions = HttpGetOptions<HttpPaginationParams>;

@Injectable()
export class GeographyApiService {
  private readonly http = inject(HttpClient);
  private readonly API_ORIGIN = inject(GEOGRAPHY_API_ORIGIN);

  public getStores(options?: HttpGetStoresOptions) {
    return this.http.get<HttpResponseWithPagination<GeographyStoreXhr[]>>(this.API_ORIGIN.store, options as unknown as HttpGetOptions).pipe(
      map((response) => {
        return {
          pagination: { ...response.pagination },
          data: response.data.map(createGeographyStore),
        };
      }),
    );
  }

  public getCompanies(options?: HttpGetStoresOptions) {
    return this.http
      .get<HttpResponseWithPagination<GeographyCompanyXhr[]>>(this.API_ORIGIN.company, options as unknown as HttpGetOptions)
      .pipe(
        map((response) => {
          return {
            pagination: { ...response.pagination },
            data: response.data.map(createGeographyCompany),
          };
        }),
      );
  }
}
