import { inject } from '@angular/core';
import { createPopupButton, DialogService } from '@isaia/components/dialog';
import { translate } from '@jsverse/transloco';
import { firstValueFrom, map } from 'rxjs';
import { injectEmailContentModal } from '../../shared/email-content-modal';
import { AuthRepository } from '../auth';

interface ChooseSendToProductionEmailContentOptions {
  orderId: string;
  storeName: string;
  customerFullName: string;
  salesAssociateFullName: string;
}

export function injectOrderDialog() {
  const dialogService = inject(DialogService);
  const emailContentModal = injectEmailContentModal();
  const authRepository = inject(AuthRepository);

  return {
    openCheckoutSuccess: () => {
      const popup$ = dialogService
        .openPopup({
          title: translate('popupCheckoutSuccessTitle'),
          message: authRepository.isContextWholesale() ? '' : translate('popupCheckoutSuccessMessage'),
          buttons: [createPopupButton({ label: translate('popupCheckoutSuccessButtonOk') })],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    openSendItemToReadyForProductionSuccess: () => {
      const popup$ = dialogService
        .openPopup({
          title: translate('popupSendItemToReadyForProductionSuccessTitle'),
          message: translate('popupSendItemToReadyForProductionSuccessMessage'),
          buttons: [createPopupButton({ label: translate('popupSendItemToReadyForProductionSuccessButtonOk') })],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    openUpdateOrderStoreMismatch({ currentStore, orderStore }: { orderStore: string; currentStore: string }) {
      const popup$ = dialogService
        .openPopup({
          title: translate('popupUpdateOrderStoreMismatchTitle'),
          message: translate('popupUpdateOrderStoreMismatchMessage', { orderStore, currentStore }),
          buttons: [createPopupButton({ label: translate('popupUpdateOrderStoreMismatchButtonOk') })],
        })
        .afterClosed();
      return firstValueFrom(popup$);
    },

    openChooseSendToProductionEmailContent(options: ChooseSendToProductionEmailContentOptions) {
      return emailContentModal
        .open({
          data: {
            modalTitle: translate('emailSendToProduction.modalTitle'),
            subject: translate('emailSendToProduction.subject', {
              orderId: options.orderId,
              store: options.storeName,
              customerFullName: options.customerFullName,
            }),
            body: translate('emailSendToProduction.body', {
              customerFullName: options.customerFullName,
              salesAssociateFullName: options.salesAssociateFullName,
            }),
          },
        })
        .afterClosed();
    },

    openPhotosWarning() {
      return dialogService
        .openPopup({
          title: translate('popupItemIsWithoutPhotosTitle'),
          message: translate('popupItemIsWithoutPhotosMessage'),
          buttons: [
            createPopupButton({ onTap: false, label: translate('popupItemIsWithoutPhotosTitleButtonStop') }),
            createPopupButton({ onTap: true, label: translate('popupItemIsWithoutPhotosTitleButtonProceed'), type: 'flat' }),
          ],
        })
        .afterClosed()
        .pipe(map((event) => event?.action));
    },

    openChooseExternalReferenceId(externalReferenceIds: string[]) {
      const popup$ = dialogService
        .openPopup({
          title: translate('popupChooseExternalReferenceIdTitle'),
          message: translate('popupChooseExternalReferenceIdMessage'),
          buttons: externalReferenceIds.map((id) => {
            return createPopupButton({ onTap: id, label: id });
          }),
        })
        .afterClosed()
        .pipe(map((event) => event?.action));
      return firstValueFrom(popup$);
    },

    openSmtpError() {
      const popup$ = dialogService
        .openPopup(
          {
            title: translate('popupErrorSmtpOrderTitle'),
            message: translate('popupErrorSmtpOrderMessage'),
            buttons: [createPopupButton({ label: 'Ok' })],
          },
          { disableClose: false },
        )
        .afterClosed();
      return firstValueFrom(popup$);
    },
  };
}
