import { AuthUser } from './auth-user.model';
import { jwtDecode } from 'jwt-decode';
import { AuthPermissions } from './auth-permission.model';

export interface IsaiaJwtPayload {
  defaultStore: string;
  allowedStores: string;
  productionEmails: string;
  permissions: AuthPermissions;
  given_name: string;
  family_name: string;
  email: string;
  username: string;
  sub: string;
  iss: string;
  availablePriceLists: string;
  version: number;
  client_id: string;
  origin_jti: string;
  token_use: string;
  scope: string;
  auth_time: number;
  defaultPriceList: string;
  exp: number;
  iat: number;
  jti: string;
}

type GetCognitoValue = {
  (prop: keyof IsaiaJwtPayload, param: { toArray?: boolean }): string[];
  (prop: keyof IsaiaJwtPayload): string;
  (prop: keyof IsaiaJwtPayload, param?: { toArray?: boolean }): string | string[];
};

export const REQUIRED_COGNITO_USER_PROPS: Array<keyof AuthUser> = ['email', 'username'];

const MOCK_WHOLESALE: Partial<IsaiaJwtPayload> = {
  defaultStore: '004668',
  allowedStores: '004668,003940,004247,003043,003470,003470',
};

function getCognitoValueFromToken(payload: IsaiaJwtPayload): GetCognitoValue {
  return (prop: keyof IsaiaJwtPayload, options?: { toArray?: boolean }): any => {
    // const value = MOCK_WHOLESALE[prop] || payload[prop];
    const value = payload[prop];

    if (typeof value !== 'string') {
      return value;
    }

    if (options?.toArray) {
      return (
        value
          .split(',')
          .filter((v) => !!v)
          .map((v) => v.trim()) || []
      );
    }
    return value.trim();
  };
}

export function createAuthUserFromCognito(token?: string): AuthUser | undefined {
  const decoded = token ? jwtDecode<IsaiaJwtPayload>(token) : undefined;

  if (decoded) {
    const getValueFrom = getCognitoValueFromToken(decoded);
    return {
      username: decoded.username,
      firstName: getValueFrom('given_name'),
      lastName: getValueFrom('family_name'),
      email: getValueFrom('email'),
      defaultStore: getValueFrom('defaultStore'),
      sentToProductionEmails: getValueFrom('productionEmails', { toArray: true }),
      allowedStores: getValueFrom('allowedStores', { toArray: true }),
      permissions: getValueFrom('permissions', { toArray: true }),
    } satisfies AuthUser;
  }

  return undefined;
}
